"use client";

import React from "react";

import { useField } from "formik";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { subtractDays } from "@/utils/helper";
import useMediaQuery from "@/hooks/useMediaQuery";
import { usePathname } from "next/navigation";
// import "react-multi-date-picker/styles/layouts/mobile.css"
interface DateSearchProps {
  name: string;
  value: string[] | DateObject[];
  setFieldValue: (field: string, value: any) => void;
}

const DateSearch: React.FC<DateSearchProps> = ({ name, value, setFieldValue }) => {
  const currentPage = usePathname();
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const [, meta] = useField(name); 
  // Handle date changes
  const handleDateChange = (dates: DateObject[]) => {
    const formattedDates = dates?.map((date) => date?.format("YYYY-MM-DD"));
    setFieldValue(name, formattedDates); // Update formik value
  };
  //Calculate the max date range (30 days from the start date)
  const startDate = value && value[0] ? new DateObject(value[0]) : null;
  const maxDate: any = startDate ? new DateObject(startDate).add(30, "days") : null;

  return (
    <div className="text-15 text-light-1 ls-2 lh-16 custom_dual_datepicker">
      <DatePicker
      //  className="rmdp-mobile"
        inputClass={`custom_input-picker ${meta?.touched && meta?.error ? 'input-bottom-error' : ''}`}
        containerClassName="custom_container-picker"
        value={value?.map((date) => new DateObject(date))} // Convert from YYYY-MM-DD to DateObject for display
        onChange={handleDateChange}
        numberOfMonths={isDesktop ? 2 : 1}
        offsetY={isDesktop ? 10 : 0}
        range
        rangeHover
        format="MMM DD" // Display format
        minDate={new DateObject()}
        disabled={currentPage && currentPage == "/hotel" ? true : false}
        // maxDate={maxDate} 
      />
    </div>
  );
};

export default DateSearch;

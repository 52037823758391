"use client";

import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { useField } from "formik";
import { usePathname } from "next/navigation";

interface Room {
  adults: number;
  children_ages: (number | null)[];
  no_of_infants?: number | undefined;
}
interface GuestSearchProps {
  name: string;
  guests: Room[];
  setFieldValue: (name: string, value: any) => void;
}

const Counter: React.FC<{
  label: string;
  value: any;
  onChange: (value: any) => void;
  min?: number;
  max?: number;
}> = ({ label, value, onChange, min = 0, max = 10 }) => (
  <div className="row y-gap-10 justify-between items-center">
    <div className="col-auto">
      <div className="text-14 sm:text-12 xs:text-10 lh-12 fw-500">{label}</div>
    </div>
    <div className="col-auto">
      <div className="d-flex items-center">
        <button
          type="button"
          className="button -outline-blue-1 text-blue-1 size-38 md:size-20 rounded-4"
          onClick={() => onChange(Math.max(value - 1, min))}
        >
          <i className="icon-minus text-12" />
        </button>
        <div className="flex-center size-20  ml-5 mr-5">
          <div className="text-14 sm:text-12 xs:text-10">{value}</div>
        </div>
        <button
          type="button"
          className="button -outline-blue-1 text-blue-1 size-38 md:size-20 rounded-4"
          onClick={() => onChange(Math.min(value + 1, max))}
        >
          <i className="icon-plus text-12" />
        </button>
      </div>
    </div>
  </div>
);

const GuestSearch: React.FC<GuestSearchProps> = ({
  name,
  guests,
  setFieldValue,
}) => {
  
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const currentPage = usePathname();
  const isHotelPage = currentPage === "/hotel";
  // const [isMounted, setIsMounted] = useState<boolean>(false);

  const [, meta] = useField(name);

  const handleRoomChange = useCallback((index: number, key: keyof Room, value: any) => {
    const updatedGuests = guests?.map((guest, i) => {
      if (i === index) {
        // Update `no_of_infants` if the age value is 0
        const newChildrenAges =
          key === "children_ages"
            ? (value as (number | null)[])
            : guest?.children_ages;

        const no_of_infants = newChildrenAges?.filter(
          (age) => age === 0
        ).length;

        return {
          ...guest,
          [key]: value,
          no_of_infants: no_of_infants > 0 ? no_of_infants : undefined,
        };
      }
      return guest;
    });

    setFieldValue("guests", updatedGuests);
  }, [guests, setFieldValue]);

  const addRoom = () => {
    const newGuests = [...guests, { adults: 1, children_ages: [] }];
    setFieldValue("guests", newGuests);
  };

  const removeRoom = (index: number) => {
    const updatedGuests = guests.filter((_, i) => i !== index);
    setFieldValue("guests", updatedGuests);
  };

  // useEffect(() => {
  //   setIsMounted(true);
  // }, []);

  // if (!isMounted) {
  //   return null;
  // }

  const createOptions = Array.from({ length: 18 }, (_, i) => ({
    value: i,
    label: `${i === 0 ? "Under 1" : i}`,
  }));

  return (
    <div
      className={`${
        currentPage == "/hotel-single-v1"
          ? "searchMenu-guests px-20 py-10 border-light rounded-4 js-form-dd js-form-counters"
          : "searchMenu-guests px-30 sm:py-10 sm:mb-20 lg:py-20 lg:px-0 js-form-dd js-form-counters position-relative"
      }`}
    >
      <div
        data-bs-toggle={isHotelPage ? undefined : "dropdown"}
        data-bs-auto-close="outside"
        aria-expanded="false"
        data-bs-offset="0,22"
        tabIndex={0}
        onClick={(e) => {
          if (isHotelPage) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        <h4 className="text-15 fw-500 ls-2 lh-16">Guest</h4>
        
        <div className="text-15 text-light-1 ls-2 lh-16">
          {guests?.length} rooms -{" "}
          { guests?.reduce((acc, room) => acc + room.adults, 0)} adults -{" "}
          {/* {guests?.reduce((acc, room) => acc + (room.no_of_infants ?? 0), 0) > 0
            ? guests.reduce((acc, room) => acc + (room.no_of_infants ?? 0), 0)
            : 0}{" "}
          infants -{" "} */}
          {guests?.reduce((acc, room) => acc + room.children_ages?.length, 0)}{" "}
          children
        </div>
      </div>
      {!isHotelPage && (
      <div className="shadow-2 dropdown-menu min-width-dropdown-400">
      {/* scrollable-container */}
        <div className="bg-white px-30 py-30 sm:px-15 sm:py-15 rounded-4 counter-box">
          {guests?.map((room, roomIndex) => (
            <div key={roomIndex} className="mb-10">
              <h4 className="text-14 sm:text-12 xs:text-10 fw-500 ls-2 lh-16">
                Room {roomIndex + 1}
              </h4>
              <Counter
                label="Adults"
                value={room.adults}
                onChange={(value) =>
                  handleRoomChange(roomIndex, "adults", value)
                }
                min={1}
                max={9}
              />
              {/* <Counter
                label="No. of infants"
                value={room.no_of_infants || 0}
                onChange={(value) => handleRoomChange(roomIndex, "no_of_infants", value)}
                min={0}
                max={9}
              /> */}
              <Counter
                label="Children (ages 0 to 17)"
                value={room.children_ages?.length}
                onChange={(value) => {
                  handleRoomChange(roomIndex, "children_ages", 
                      value > room.children_ages.length
                        ? [...room.children_ages, 1]
                        : room.children_ages.slice(0, -1)
                    )
                  // const updatedChildren = [...room.children_ages];
                  // if (value > room.children_ages.length) {
                  //   updatedChildren.push(1);
                  // } else {
                  //   updatedChildren.pop();
                  // }
                  // handleRoomChange(roomIndex, "children_ages", updatedChildren);
                }}
                min={0}
                max={8}
              />
              <div className="row y-gap-10 justify-between items-center" >
                {room?.children_ages?.map((child, childIndex) => (
                  <>
                    <div className="text-15 sm:text-12 xs:text-10 mr-30 col-auto">
                      Child {childIndex + 1} Age:
                    </div>

                    <div className="col-md-6 col-sm-2">
                      <Select
                        className="react-select-container sm:text-12 xs:text-10"
                        classNamePrefix="react-select"
                        options={createOptions}
                        value={{
                          value: child,
                          label: `${
                            child === 0 ? "Under 1" : child
                          }`,
                        }}
                        onChange={(selectedOption) => {
                          const updatedChildren = [
                            ...room.children_ages.slice(0, childIndex),
                            selectedOption?.value,
                            ...room.children_ages.slice(childIndex + 1),
                          ];
                          handleRoomChange(
                            roomIndex,
                            "children_ages",
                            updatedChildren
                          );
                        }}
                      />
                    </div>
                  </>
                ))}
              </div>
              {roomIndex > 0 && (
                <div className="d-flex justify-end">
                  <button
                    type="button"
                    onClick={() => removeRoom(roomIndex)}
                    className="mt-10 py-10 button -outline-red-1 text-red-1 w-50 sm:text-12"
                  >
                    Remove room
                  </button>
                </div>
              )}
              <hr className="border-dark my-20" />
            </div>
          ))}
          {guests?.length <= 19 && (
            <div className="d-flex justify-end">
              <button
                type="button"
                onClick={addRoom}
                className="button py-10 -dark-1 bg-blue-1 text-white w-50 sm:text-10"
              >
                Add another room
              </button>
            </div>
          )}
        </div>
      </div>
      )}
    </div>
  );
};

export default GuestSearch;
